import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export const Header = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div >
      <div id="header">
        <div>
          <div id="header-logo-parent" onClick={() => navigate('/')}>
            <img id="header-logo" src="/imgs/header-logo.png" />
            <div id="header-text">Look Lemons</div>
          </div>
        </div>
        <div id="routes">
          <div>
            <Link to="/" >Home</Link></div>
          <div>
            <Link to="/tracks" >Tracks</Link></div>
          <div>
            <Link to="/about" >About</Link></div>
          <div>
            <Link to="/contact" >Contact</Link></div>
        </div>
        <div><span id="menu-button" onClick={() => setMenuOpen(!menuOpen)}>Menu</span></div>
      </div>
      {menuOpen && (
        <div id="mobile-menu">
          <div id="menu-list">
            <div onClick={() => {
              navigate('/')
              window.scrollTo(0, 0)
              setMenuOpen(false)
            }}>Home</div>
            <div onClick={() => {
              navigate('/tracks')
              window.scrollTo(0, 0)
              setMenuOpen(false)
            }}>Tracks</div>
            <div onClick={() => {
              navigate('/about')
              window.scrollTo(0, 0)
              setMenuOpen(false)
            }}>About</div>
            <div onClick={() => {
              navigate('/contact')
              setMenuOpen(false)
            }}>Contact</div>
          </div>
        </div>
      )}
    </div>
  );
}