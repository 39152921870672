import { Footer } from "../components/Footer";
import { Header } from "../components/Header";

export const About = () => {
  return (
    <div>
      <Header />
      <div className="page-content">
        <div className="green-cover">
          <div className="cover-content about">
            <div id="home-image-parent">
              <img id="home-image" src="/imgs/front-page.png" />
            </div>
            <div id="text-section">
              <div id="black-box">
                <div className="heading" style={{ marginBottom: 20 }}>About Me</div>
                <div className="about-text" style={{ marginBottom: 20, lineHeight: 1.5 }}>
                  Hi, my name is Cameron. 
                  I live on the Gold Coast Australia.
                </div>
                <div className="about-text" style={{ marginBottom: 20, lineHeight: 1.5 }}>
                  My passion for music started when I heard a classic house track Buy Now - For Sale
                  by Steve Angello and Sebastian Ingrosso on the radio.
                  It immediately hooked my brain and I remember I was obsessed on working out how the rhythm worked.
                </div>
                <div className="about-text" style={{ marginBottom: 20, lineHeight: 1.5 }}>
                  I first started making music in 2008 on a school laptop. Then I decided to start taking it more seriously and got myself on Radio Metro.
                </div>
                <div className="about-text" style={{ marginBottom: 20, lineHeight: 1.5 }}>
                  Then I had some unfortunately experiences and realised my music wasn't up to the standard I wanted,
                  so I spent time honing my mixing and mastering skills and did a course at Berklee Online.
                </div>
                <div className="about-text" style={{ marginBottom: 20, lineHeight: 1.5 }}>
                  Now I'm much happier with my sound and spend time coming up with new ideas and from start to finish and love the whole process.
                </div>
                <div className="about-text" style={{ marginBottom: 20, lineHeight: 1.5 }}>
                  I hope you enjoy my music as much as I do &lt;3
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}