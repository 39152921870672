export const Footer = () => {
  return (
    <div id="footer">
      <div id="footer-content">
        <div>
          <div id="footer-img-parent">
            <img id="footer-logo" src="/imgs/header-logo.png" />
          </div>
          <div id="footer-motto">When life gives you lemons, you make the most of it!</div>
        </div>
      </div>

    </div>
  );
}