import { Footer } from "../components/Footer";
import { Header } from "../components/Header";

export const Tracks = () => {
  return (
    <div>
      <Header />
      <div className="page-content">
        <div className="green-cover track-cover">
          <div className="cover-content">
            <div id="spotify-section">
              <iframe style={{ borderRadius:12 }} src="https://open.spotify.com/embed/track/4ColRJFFQe2zcp1ZFht9Lz?utm_source=generator&theme=0" width="300" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </div>
            <div id="text-section">
              <div id="black-box">
                <div className="heading" style={{ marginBottom: 20 }}>Benny</div>
                <div className="track-text" style={{ marginBottom: 20, lineHeight: 1.6 }}>
                  I made this track about the love of my son Benny. He brings me so much happiness and joy in my life.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="black-cover track-cover">
          <div className="cover-content">
            <div id="spotify-section">
              <iframe style={{ borderRadius:12 }} src="https://open.spotify.com/embed/track/4YIO4fLFbkwNc7q3oAamMS?utm_source=generator&theme=0" width="300" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>            </div>
            <div id="text-section">
              <div id="black-box">
                <div className="heading" style={{ marginBottom: 20 }}>Verb</div>
                <div className="track-text" style={{ marginBottom: 20, lineHeight: 1.6 }}>
                  This is a mean Drum n Bass track with too much reverb haha hence the name. It has very much a Tron vibe.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="green-cover track-cover">
          <div className="cover-content">
            <div id="spotify-section">
              <iframe style={{ borderRadius:12 }} src="https://open.spotify.com/embed/track/7eVnVTdIZcvv8Ktv9ImuGX?utm_source=generator&theme=0" width="300" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>            </div>
            <div id="text-section">
              <div id="black-box">
                <div className="heading" style={{ marginBottom: 20 }}>Down</div>
                <div className="track-text" style={{ marginBottom: 20, lineHeight: 1.6 }}>
                  This track is mellow and really gives me a sad but inspirational feeling that everything is going to be ok. Lots of emotion in this one and more down tempo than my other tracks.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="black-cover track-cover">
          <div className="cover-content">
            <div id="spotify-section">
              <iframe style={{ borderRadius:12 }} src="https://open.spotify.com/embed/track/22Fzy7Gh44ZFe4Unv3zgAF?utm_source=generator&theme=0" width="300" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>            </div>
            <div id="text-section">
              <div id="black-box">
                <div className="heading" style={{ marginBottom: 20 }}>Let's Go</div>
                <div className="track-text" style={{ marginBottom: 20, lineHeight: 1.6 }}>
                  This is the track you've gotta play when you've gotta get something done! Very much driven by my go getter attitude.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="green-cover track-cover">
          <div className="cover-content">
            <div id="spotify-section">
              <iframe style={{ borderRadius:12 }} src="https://open.spotify.com/embed/track/0dJMfYUgjwTzWVhMdN8SQY?utm_source=generator&theme=0" width="300" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>            </div>
            <div id="text-section">
              <div id="black-box">
                <div className="heading" style={{ marginBottom: 20 }}>Welcome to Australia</div>
                <div className="track-text" style={{ marginBottom: 20, lineHeight: 1.6 }}>
                  This track is all about funny, typical Aussie culture. So many Aussie slang words in this one and the drop even sounds like a didgeridoo!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}