import { Header } from "../components/Header";

export const Contact = () => {
  return (
    <div>
      <Header />
      <div className="page-content">
        <div className="black-cover">
          <div className="cover-content contact">
            <div style={{ color: 'white', textAlign: 'center' }}>
              <div className="contact-text" style={{ marginBottom: 25 }}>If you want to contact me, you can contact me on:</div>
              <div id="email-link"><a href="mailto:looklemonsmusic@gmail.com">looklemonsmusic@gmail.com</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}