import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { Home } from "./pages/Home";
import { Tracks } from "./pages/Tracks.js";
import { Contact } from "./pages/Contact";
import { About } from "./pages/About";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/tracks",
    element: <Tracks />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);